<template>
  <main class="mentions">
    <h2>Mentions légales</h2>
    <p>
      La première version de ce projet a été réalisée dans le cadre du concours OpenData organisé par la ville Strasbourg et pour répondre à un besoin quotidien : disposer sur n'importe quel périphérique de consultation web (pc fixe, tablette, mobile) d'un accès rapide et léger aux informations (arrêts, horaires, plan, trafic) du réseau de transport de l'Eurométropole (CTS).
    </p>
    <h3>OpenData</h3>
    <p>
      Le jeu de données est efficacement mis à disposition par la
      <abbr title="Compagnie des Transports Strasbourgeois">CTS</abbr> et la ville de Strasbourg.
    </p>
    <ul>
      <li>
        <a href="https://www.cts-strasbourg.eu/fr/portail-open-data/">Portail Open Data CTS</a>
      </li>
      <li>
        <a
          href="https://data.strasbourg.eu"
        >Portail Open Data Ville de Strasbourg</a>
      </li>
    </ul>
    <h3>Technologies</h3>
    <p>
      Les langages et outils mis en oeuvre sont
      <a href="https://vuejs.org/">Vue.js</a>, <a href="https://github.com/axios/axios">Axios</a>, HTML5, CSS3, et JavaScript. La fonctionnalité de géolocalisation se base sur HTML5 qui exploite la triangulation GPS, GSM, WiFi, ou tout autre moyen à disposition dans le navigateur web.
    </p>
    <h3>Données personnelles</h3>
    <p>Aucune collecte de données n'est réalisée sur ce service. Votre navigateur mémorise uniquement la liste des arrêts que vous placez dans les favoris, liste que vous pouvez modifier à tout moment.</p>
    <h3>Hébergement</h3>
    <p>Cette application web est développée et hébergée par Alsacréations sur des serveurs ultra-puissants.</p>
    <h3>Contact</h3>
    <ul>
      <li>
        <a href="http://www.blup.fr/contact/">Contactez-moi</a> pour proposer des améliorations et signaler les bugs.
      </li>
    </ul>
  </main>
</template>

<script>

export default {
  head: {
    title: 'Directs',
    titleTemplate: '%s - Mentions légales',
    htmlAttrs: {
      lang: 'fr'
    }
  },
};
</script>

<style lang="scss">

.mentions {
  p, h3, ul {
    margin: 1rem 2rem;
  }
}

</style>
